<!--
 * @Description: 
 * @Author: ChenXueLin
 * @Date: 2022-09-09 10:17:57
 * @LastEditTime: 2022-09-13 13:44:43
 * @LastEditors: ChenXueLin
-->
<template>
  <!-- 客户列表弹框 -->
  <el-dialog
    v-dialogDrag
    title="客户列表"
    :visible.sync="corpVisible"
    width="800px"
    :before-close="closeDialog"
    :close-on-click-modal="false"
    v-loading="dialogLoading"
    @open="handleOpen"
    :element-loading-background="loadingBackground"
  >
    <el-form class="search-list" ref="searchForm" :model="searchForm">
      <el-form-item class="search-item--1" prop="corpName">
        <el-input
          v-model="searchForm.corpName"
          placeholder="客户名称"
          title="客户名称"
        ></el-input>
      </el-form-item>
      <el-form-item class="search-item--buttons">
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button class="reset" @click="handleReset">重置</el-button>
      </el-form-item>
    </el-form>

    <el-table
      @selection-change="selectionCorpChange"
      border
      :data="tableData"
      highlight-current-row
      height="400px"
      ref="elTable"
      :row-key="
        row => {
          return row.workCorpId;
        }
      "
    >
      <el-table-column
        type="selection"
        width="50"
        :reserve-selection="true"
      ></el-table-column>
      <el-table-column label="客户ID" prop="workCorpId" align="center">
      </el-table-column>
      <el-table-column label="客户名称" prop="corpName" align="center">
      </el-table-column>
    </el-table>
    <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
      <el-pagination
        :page-size.sync="searchForm.pageSize"
        :current-page.sync="searchForm.pageIndex"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </section>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="pushData">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import { printError } from "@/utils/util";
import { getWorkCorpList } from "@/api";
export default {
  name: "",
  components: {},
  data() {
    return {
      searchForm: {
        corpName: "",
        pageIndex: 1,
        pageSize: 20
      },
      dialogLoading: false,
      tableData: [],
      total: 0,
      selColumn: []
    };
  },
  props: ["corpVisible"],
  mixins: [base, listPage],
  computed: {},
  watch: {},
  methods: {
    handleOpen() {
      this.queryList();
    },
    //查询全部任务单
    async queryList() {
      try {
        this.dialogLoading = true;
        let res = await getWorkCorpList(this.searchForm);
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //选择客户
    selectionCorpChange(columns) {
      this.selColumn = columns;
    },
    //关闭客户列表弹框
    closeDialog() {
      this.$refs.elTable.clearSelection();
      this.selColumn = [];
      this.$emit("close");
    },
    pushData() {
      this.$emit("updateCorp", this.selColumn);
      this.closeDialog();
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped></style>
