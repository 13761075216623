<!--
 * @Description:客户安装工艺列表
 * @Author: ChenXueLin
 * @Date: 2021-10-13 19:26:59
 * @LastEditTime: 2022-09-14 16:48:48
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="technologyName">
              <el-input
                v-model="searchForm.technologyName"
                placeholder="安装工艺名称"
                title="安装工艺名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpName">
              <el-input
                v-model="searchForm.corpName"
                placeholder="客户名称"
                title="客户名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="新增" @click="handleAdd"></i>
          <i
            class="e6-icon-check_line1"
            title="启用"
            @click="changeStatus(1)"
          ></i>
          <i
            class="e6-icon-prohibit_line"
            title="禁用"
            @click="changeStatus(0)"
          ></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
          @selection-change="handleSelectionChange"
          :row-key="
            row => {
              return row.technologyId;
            }
          "
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            :render-header="renderHeader"
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <el-dialog
      v-dialogDrag
      :title="isAdd ? '添加' : '编辑'"
      :visible.sync="addVisible"
      width="800px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="custom-dialog"
    >
      <el-form
        ref="addForm"
        label-width="120px"
        :model="addForm"
        :rules="addFormRules"
      >
        <el-form-item label="安装工艺名称" prop="technologyName">
          <el-input
            v-model.trim="addForm.technologyName"
            placeholder="安装工艺名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="安装工艺文件" prop="fileId">
          <uploadOBS
            v-model="addForm.fileId"
            :fileType="1"
            :limit="1"
            :uploadFileList.sync="contractFileList"
            buttonText="点击上传附件"
          ></uploadOBS>
        </el-form-item>
        <el-form-item label="绑定客户" prop="rangeTypeCode">
          <el-radio v-model="addForm.rangeTypeCode" :label="1">自定义</el-radio>
          <el-radio v-model="addForm.rangeTypeCode" :label="0">
            全部客户
          </el-radio>
        </el-form-item>
      </el-form>
      <div v-show="addForm.rangeTypeCode == 1">
        <div style="margin-bottom:15px;">
          <el-button type="primary" @click="addCorp">添加</el-button>
          <el-button type="primary" @click="deleteRow">删除</el-button>
        </div>
        <el-table
          @selection-change="handleSelectCorp"
          border
          :data="tableList"
          highlight-current-row
          ref="corpListTable"
          height="300px"
          :row-key="
            row => {
              return row.workCorpId;
            }
          "
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <!--  :reserve-selection="true" -->
          <el-table-column label="客户ID" prop="workCorpId" align="center">
          </el-table-column>
          <el-table-column label="客户名称" prop="corpName" align="center">
          </el-table-column>
        </el-table>
        <section
          class="pagination-wrapper fixed-section"
          ref="paginationWrapper"
        >
          <el-pagination
            :page-size.sync="pageSetting.pageSize"
            :current-page.sync="pageSetting.pageIndex"
            :page-sizes="pageSetting.pageSizes"
            :layout="layout"
            :total="workCorpTotal"
            background
            @size-change="pageSizeChange"
            @current-change="currentChange"
          ></el-pagination>
        </section>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="toAdd">确定</el-button>
      </div>
    </el-dialog>
    <corp-dialog
      :corpVisible="corpVisible"
      @close="close"
      @updateCorp="updateCorp"
    ></corp-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import uploadOBS from "@/components/uploadOBS/uploadOBS";
import corpDialog from "./components/corpDialog";
import { exportExcelByUrl } from "@/utils/download";
import {
  getTechnologyList,
  enableTechnology,
  updateTechnology,
  createTechnology,
  downFile
} from "@/api";
export default {
  name: "installProcessManage",
  data() {
    return {
      searchForm: {
        technologyName: "",
        corpName: "",
        pageIndex: 1,
        pageSize: 20
      },
      total: 0,
      columnData: [
        {
          fieldName: "technologyId",
          display: true,
          fieldLabel: "安装工艺ID",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "technologyName",
          display: true,
          fieldLabel: "安装工艺名称",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "isenable",
          display: true,
          fieldLabel: "安装工艺状态",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "rangeCorp",
          display: true,
          fieldLabel: "绑定客户",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      addForm: {
        fileId: "",
        technologyName: "",
        rangeTypeCode: 0
      },
      addFormRules: {
        technologyName: [
          {
            required: true,
            message: "请输入安装工艺名称",
            trigger: ["blur", "change"]
          }
        ],
        fileId: [
          {
            required: true,
            message: "请上传安装工艺附件",
            trigger: ["blur", "change"]
          }
        ],
        rangeTypeCode: [
          {
            required: true,
            message: "请选择绑定客户",
            trigger: ["blur", "change"]
          }
        ]
      },
      workCorpList: [], //选中的所有客户列表
      tableList: [], //当前页面显示的数据
      pageSetting: {
        pageIndex: 1,
        pageSize: 20, // 每页默认条数
        workCorpTotal: 0,
        pageSizes: [10, 20, 50, 100, 200, 500] // 每页条数选择
      },
      contractFileList: [], //附件列表
      addVisible: false,
      dialogLoading: false,
      isAdd: true,
      corpVisible: false
    };
  },
  components: { uploadOBS, corpDialog },
  mixins: [listPage, listPageReszie, base],
  watch: {},
  created() {
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  computed: {
    workCorpTotal() {
      return this.workCorpList.length;
    }
  },
  methods: {
    //查询全部任务单
    async queryList() {
      try {
        this.loading = true;
        let res = await getTechnologyList(this.searchForm);
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },

    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        },
        {
          label: "查看安装工艺",
          id: 2
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.isAdd = false;
        this.addVisible = true;
        this.addForm = { ...row };
        // this.addForm.technologyName = row.technologyName;
        this.workCorpList = row.corpReqVOS;
        this.contractFileList = [{ fileId: row.fileId, name: row.fileName }];
        this.handlePage();
        this.dowmloadFile(row.fileId, "edit");
      }
      if (val.id == 2) {
        this.dowmloadFile(row.fileId);
      }
    },
    // 下载附件
    async dowmloadFile(id, type = "") {
      try {
        this.loading = true;
        let res = await downFile({ id });
        let url = this.getFreezeResponse(res, {
          path: "data.fileUrl",
          defaultVal: ""
        });
        if (type == "edit") {
          this.contractFileList[0].fileUrl = url;
        } else {
          if (url) {
            exportExcelByUrl(url);
          }
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => item.technologyId);
    },
    //启用、禁用toAdd
    changeStatus(type) {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择数据");
        return;
      }
      let tip, title;
      if (type == 1) {
        tip = "是否确认启用";
        title = "启用";
      } else {
        tip = "是否确认禁用";
        title = "禁用";
      }
      this.$confirm(tip, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.enableErrorReq(type);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //启用/禁用请求
    async enableErrorReq(state) {
      try {
        this.loading = true;
        let res = await enableTechnology({
          technologyIdS: this.selColumnId,
          state
        });
        if (res.code == "OK") {
          if (state == 0) {
            this.$message.success("禁用成功");
          } else {
            this.$message.success("启用成功");
          }
          this.selColumnId = [];
          this.$refs.elTable.clearSelection();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //添加安装位置
    handleAdd() {
      this.addVisible = true;
      this.isAdd = true;
    },
    // 筛选文件类型和大小
    handleBeforeUpload(file) {
      if (!/^image\/(jpeg|png|bmp|gif|jpg)$/.test(file.type)) {
        this.$message.info("上传图片必须是JPG/PNG/BMP 格式!");
        return false;
      }
      if (file.size / 1024 / 1024 > 1) {
        this.$message.info("上传文件大小不能超过 1MB!");
        return false;
      }
      return true;
    },
    toAdd() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          if (this.addForm.rangeTypeCode == 1 && !this.workCorpList.length) {
            this.$message.warning("请选择客户");
            return;
          }
          if (this.isAdd) {
            this.createReq();
          } else {
            this.updateReq();
          }
        }
      });
    },
    //新增请求
    async createReq() {
      try {
        this.dialogLoading = true;
        this.addForm.fileName = this.contractFileList[0].name;
        let res = await createTechnology({
          ...this.addForm,
          corpReqVOS: this.workCorpList
        });
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.handleClose();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //修改请求
    async updateReq() {
      try {
        this.selectLoading = true;
        this.addForm.fileName = this.contractFileList[0].name;
        let res = await updateTechnology({
          ...this.addForm,
          corpReqVOS: this.workCorpList
        });
        if (res.code == "OK") {
          this.$message.success("修改成功");
          this.handleClose();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //关闭弹框
    handleClose() {
      this.addForm = {
        fileId: "",
        fileName: "",
        technologyName: "",
        rangeTypeCode: 0
      };
      this.contractFileList = [];
      this.workCorpList = [];
      this.tableList = [];
      this.$refs.corpListTable.clearSelection();
      this.$refs.addForm.resetFields();
      this.addVisible = false;
    },
    /*****添加客户********/
    //前端分页
    handlePage() {
      this.tableList = this.workCorpList.slice(
        (this.pageSetting.pageIndex - 1) * this.pageSetting.pageSize,
        this.pageSetting.pageIndex * this.pageSetting.pageSize
      );
    },
    //改变页面显示数据条数
    pageSizeChange(pageSize) {
      if (this.tableData) {
        if (pageSize) {
          this.pageSetting.pageSize = pageSize;
        }
        this.handlePage();
      }
    },
    currentChange(currentChange) {
      this.pageSetting.pageIndex = currentChange;
      this.handlePage();
    },
    addCorp() {
      this.corpVisible = true;
    },
    close() {
      this.corpVisible = false;
    },
    updateCorp(selectData) {
      this.workCorpList = _.cloneDeep(selectData).concat(
        _.cloneDeep(this.workCorpList)
      );
      var obj = {};
      this.workCorpList = this.workCorpList.reduce((item, next) => {
        obj[next.workCorpId]
          ? ""
          : (obj[next.workCorpId] = true && item.push(next));
        return item;
      }, []);
      this.handlePage();
    },
    handleSelectCorp(columns) {
      this.selectIds = columns.map(item => item.workCorpId);
    },
    deleteRow() {
      if (!this.selectIds.length) return;
      this.workCorpList = this.workCorpList.filter(v =>
        this.selectIds.every(n => {
          return !(n == v.workCorpId);
        })
      );
      this.$refs.corpListTable.clearSelection();
      this.selectIds = [];
      this.tableList = this.workCorpList.slice(
        (this.pageSetting.pageIndex - 1) * this.pageSetting.pageSize,
        this.pageSetting.pageIndex * this.pageSetting.pageSize
      );
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 20px 18px 2px 18px;
    .el-form-item {
      .el-input {
        width: 350px;
      }
    }
    .avatar-uploader {
      .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .el-upload:hover {
        border-color: #409eff;
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 150px;
        height: 150px;
        line-height: 150px;
        text-align: center;
      }
      .avatar {
        width: 150px;
        height: 150px;
        display: block;
      }
    }
  }
}
</style>
